<template>
    <div class="power-player-page">

        <div class="power-player-dashboard">
            <div class="hero__content">
                <div class="title__row mb-3">
                    <div class="d-flex top-video-inner align-items-center">
                        <span class="d-flex mr-auto">
                            <h1 class="project__title">My Videos</h1>
                            <div >
                                <div class="project__video__count">{{ totalVideos }}</div>
                            </div>
                        </span>
                        <div class="search-button-div" style="display: inline-flex">
                            <div style="margin-right: 10px" v-if="powerVideos.length > 0 || totalVideos > 0">
                                <SearchQuery :tip="''" @query='filter'></SearchQuery>
                            </div>
                            <div>
                                <el-button type="custom" class="button__upload" round @click="addPlayerDilogue = true">
                                    Add New Video
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

<!--                        <div class="content__body" >-->
<!--                            <div class="page__content">-->
<!--                                <div class="video-no-list">-->
<!--                                    <el-row type="flex">-->
<!--                                        <el-col class="text-center">-->
<!--                                            <el-row>-->
<!--                                                <img class="no-project-img" src="../../assets/img/Image 256.svg" alt="">-->
<!--                                            </el-row>-->
<!--                                            <el-row>-->
<!--                                                <h2 class="no-list-main-text">You don’t have any Video</h2>-->
<!--                                                <div class='no-project-text pad-hor-15'>-->
<!--                                                    <span>Use PowerPlayer to play your videos hosted on</span>-->
<!--                                                    <span>3rd party platforms such as YouTube, Amazon s3 etc.</span>-->
<!--                                                </div>-->
<!--                                            </el-row>-->

<!--                                        </el-col>-->
<!--                                    </el-row>-->
<!--                                </div>-->
<!--                            </div>-->

<!--                        </div>-->
            <div class="content__body" v-if="powerVideos.length == 0 && totalVideos == 0" v-loading="loading">
                <div class="page__content">
                    <div class="video-no-list">
                        <el-row type="flex">
                            <el-col class="text-center">
                                <el-row>
                                    <img class="no-project-img" src="../../assets/img/Image 256.svg" alt="">
                                </el-row>
                                <el-row>
                                    <h2 class="no-list-main-text">You don’t have any Video</h2>
                                    <div class='no-project-text pad-hor-15'>
                                        <span>Use PowerPlayer to play your videos hosted on</span>
                                        <span>3rd party platforms such as YouTube, Amazon s3 etc.</span>
                                    </div>
                                </el-row>

                            </el-col>
                        </el-row>
                    </div>
                </div>

            </div>
            <div class="page__content" v-else-if="powerVideos.length == 0 && totalVideos > 0" v-loading="loading">
               <span class="noVideo">No videos found</span>
            </div>
            <div class="page__content" v-else v-loading="loading">
                <list :powerVideos="powerVideos" @getPowerVideos="getPowerVideos"></list>
                <pagination @getVideos="getPowerVideos" :pagination="paginationData"></pagination>
            </div>

        </div>


        <el-dialog
                title="ADD NEW VIDEO"
                center
                class="el-dialog--huge dialog_edit dialog_border_radius add-power-player-dilogue"
                :visible.sync="addPlayerDilogue"
                :modalAppendToBody="false"
        >
            <div class="relative mb-3 ">
                <el-input v-model="newVideo.title" placeholder="VIDEO TITLE"></el-input>
                <span v-if="validateVideo.title" class="error-msg">{{validateVideo.message}}</span>

                <div style="margin-top: 20px;">
                    <el-input placeholder="VIDEO URL" v-model="newVideo.path" class="input-with-select">
                        <el-select v-model="newVideo.power_player_type" slot="prepend"
                                   :placeholder="newVideo.power_player_type" :style="{'width': 150+'px'}">
                            <el-option label="YOUTUBE" value="YOUTUBE"></el-option>
                            <el-option label="MP4" value="MP4/WEBM"></el-option>
                            <el-option label="WISTIA" value="WISTIA"></el-option>
                            <el-option label="VIMEO" value="VIMEO"></el-option>
                            <el-option label="M3U8" value="M3U8/MPD"></el-option>
                            <el-option label="LIVE" value="LIVE STREAMING"></el-option>
                        </el-select>
                        <!--                                        <el-button slot="append" icon="el-icon-search"></el-button>-->
                    </el-input>
                    <span v-if="validateVideo.path" class="error-msg">{{validateVideo.message}}</span>
                </div>


            </div>

            <div slot="footer" class="dialog-footer">
                <el-button class="btn btn-primary add-power-player-button" :disabled="disabled"
                           @click="powerPlayerVideosStore">ADD VIDEO
                </el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>

    import List from '../PowerPlayer/Components/List'
    import SearchQuery from '../../components/Project/AllProjects/Components/SearchQuery'
    import Pagination from '../../components/Common/Pagination';

    export default {
        name: 'PowerPlayerDefault',
        props: {},
        components: {
            List,
            SearchQuery,
            Pagination
        },
        data: function () {
            return {
                loading: true,
                powerVideos: [],
                totalVideos: 0,
                addPlayerDilogue: false,
                newVideo: {
                    title: '',
                    power_player_type: 'YOUTUBE',
                    path: '',
                },
                validateVideo: {
                    title: false,
                    power_player_type: false,
                    path: false,
                    message: ''
                },
                paginationData: {
                    view: 10,
                    page: 1,
                    total: 0,
                    start:null,
                    end:null
                },
                disabled: false,
            }
        },
        created: function () {
            this.getPowerVideos()
        },
        methods: {
            filter(keywords) {
                this.getPowerVideos(keywords)
            },
            getPowerVideos(keywords = '') {

                if (keywords !== '') {
                    var url = `/powerPlayerVideos?keywords=${keywords}&page=${this.paginationData.page}&view=${this.paginationData.view}`;
                } else {
                    var url = `/powerPlayerVideos?page=${this.paginationData.page}&view=${this.paginationData.view}`;
                }

                this.axios.get(url)
                    .then(response => {
                        this.powerVideos = response.data.videos
                        this.paginationData.total = response.data.total;
                        this.paginationData.start = (this.paginationData.page-1)*(this.paginationData.view) +1
                        this.paginationData.end = Math.min(this.paginationData.total,(this.paginationData.page-1)*(this.paginationData.view) +this.paginationData.view )
                        this.totalVideos = response.data.total;

                        this.loading = false;
                    });
            },
            powerPlayerVideosStore() {
                this.validateVideo.title = false;
                this.validateVideo.message = '';
                this.validateVideo.path = false;
                if (this.newVideo.title == '') {
                    this.validateVideo.title = true;
                    this.validateVideo.message = 'Please enter video title';
                    return false;
                } else {
                    this.validateVideo.title = false;
                    this.validateVideo.message = '';
                }
                if (this.newVideo.path == '') {
                    this.validateVideo.path = true;
                    this.validateVideo.message = 'Please enter source URL';
                    return false;
                } else {


                    if (this.newVideo.power_player_type == 'YOUTUBE') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.newVideo.path.includes('www.youtube.com')) {
                            return false;
                        }
                    }
                    if (this.newVideo.power_player_type == 'WISTIA') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.newVideo.path.includes('wistia.com')) {
                            return false;
                        }
                    }
                    if (this.newVideo.power_player_type == 'VIMEO') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.newVideo.path.includes('vimeo.com')) {
                            return false;
                        }

                    }
                    if (this.newVideo.power_player_type == 'MP4') {

                        var re = /(?:\.([^.]+))?$/;

                        var ext = re.exec(this.newVideo.path)[1];

                        if (typeof ext == 'undefined' || ext !== "mp4") {
                            this.validateVideo.path = true;
                            this.validateVideo.message = 'Please enter valid source URL';
                            if (!this.newVideo.path.includes('vimeo.com')) {
                                return false;
                            }
                        }
                    }
                    if (this.newVideo.power_player_type == 'M3U8') {

                        var re = /(?:\.([^.]+))?$/;

                        var ext = re.exec(this.newVideo.path)[1];

                        if (typeof ext == 'undefined' || ext !== "m3u8") {
                            this.validateVideo.path = true;
                            this.validateVideo.message = 'Please enter valid source URL';
                            if (!this.newVideo.path.includes('vimeo.com')) {
                                return false;
                            }
                        }
                    }


                    this.validateVideo.path = false;
                    this.validateVideo.message = '';
                }


                this.disabled = true;
                    this.axios.post('/powerPlayerVideos/store', this.newVideo)
                        .then(response => {
    
                            this.newVideo.title = '';
                            this.newVideo.power_player_type = 'YOUTUBE';
                            this.newVideo.path = '';
    
                            this.addPlayerDilogue = false;
                            this.disabled = false;
                            this.getPowerVideos();
                        });
            },
        },
    }
</script>
<style lang="less">
    @import "./../../assets/less/custom/fonts";
    .el-scrollbar__wrap{
        min-width:160px;
    }
    .el-select-dropdown__list{
        padding:0 !important;
    }

    .top-video-inner{
        @media(max-width: 550px){
            display: block !important;
        }
        .search-button-div{
            @media(max-width: 550px){
                display: inline-flex;
                width: 100%;
                justify-content: flex-end;
                margin-top: 10px;
            }
        }
    }
    .no-project-img{
        max-width: 100%;
    }
    .power-player-page {
        background-color: var(--color-blue-light);
        font-family: "Helvetica Neue";
        font-weight: normal;
        font-style: normal;
        color: var(--color-content);
        padding: 68px 25px 20px;
        min-height: calc(~'100vh - 76px');
        box-sizing: border-box;
        @media(max-width: 480px){
            padding:10px 10px 20px  !important;
        }
        .noVideo{
            display: block;
            text-align: center;
            padding: 25px;
        }
        .power-player-dashboard {
            margin: auto 500px;

            .no-list-main-text {
                font-size: 18px;
                font-weight: 500;
            }

            .no-project-text {
                font-size: 13px;
                display: grid;
            }

            .toolbar__search {
                /*margin-right: 31px;*/

                .search__icon {
                    box-sizing: border-box;
                }

                .filter__search {
                    input.el-input__inner {
                        height: 32px;
                        font-size: 13px;
                    }

                    &:focus-within {

                    }
                }
            }

            .filter__search {
                display: flex;
                align-items: center;
                border: 1px solid #0a0a0a;
                border-radius: 16px;
                overflow: hidden;
                cursor: pointer;

                .el-input-group__prepend {
                    display: block;
                    width: auto;
                    background-color: transparent;
                    line-height: 0;
                    border: 0;
                    padding-right: 8px;
                    padding-left: 8px;

                    svg {
                        width: 16px;
                    }
                }

                input.el-input__inner {
                    box-sizing: border-box;
                    width: 0;
                    height: 32px;
                    padding-left: 0;
                    padding-right: 0;
                    font-size: 13px;
                    border: 0;
                    transition: all .3s ease-out;
                }

                svg {
                    path {
                        fill: #0a0a0a;
                    }
                }

                &:focus-within, &:hover {
                    border-color: #0a0a0a;

                    .el-input-group__prepend {
                        svg {
                            path {
                                fill: #0a0a0a;
                            }
                        }
                    }

                    input.el-input__inner {
                        width: 180px;
                        padding-right: 10px;
                    }

                    @media (max-width: 960px) {
                        input.el-input__inner {
                            width: 100px;
                        }
                    }
                }
            }

            /*            .filter__search input.el-input__inner{*/
            /*display: none;*/
            /*            }*/
            @media (max-width: 1700px) {
                margin: auto 300px !important;
            }
            @media (max-width: 1450px) {
                margin: auto 200px !important;
            }
            @media (max-width: 1200px) {
                margin: auto 100px !important;
                .player_video__body .list-power-video .power-player-detail .item__path {
                    max-width: 200px;

                }


                .player_video__body .list-power-video .power-player-detail .item__title {

                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 100px;
                }
            }
            @media (max-width: 850px) {
                margin: auto 5px !important;
            }
        }

        .add-power-player-dilogue.dialog_edit .el-dialog__title {
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: normal;
            font-size: 15px;
            color: #fff;
        }

        .add-power-player-dilogue {
            .el-dialog__headerbtn{
                font-size: 22px;
                position: absolute;
                right: 20px;
                top: 20px;
            }
            el-input-group__prepend{
                border-radius: 9px;
            }
            .el-dialog{
                max-width: 600px;
                width: 90%;
            }
            .error-msg {
                color: white;
            }
            .el-dialog__body{
                padding:30px 30px 0px;
            }
            .el-dialog--center .el-dialog__header {
                padding: 25px 40px;
            }
            .el-dialog__body {
                padding: 8px 50px!important;
            }
            .dialog_edit .dialog-footer{
                margin: 0 !important;
            }
            .el-dialog__footer{
                padding: 10px 30px 20px;
                .dialog-footer{
                    text-align: right !important;
                    width: 100% !important;
                    display: block !important;
                    padding: 0 30px 0px !important;
                }
                button{
                    color:#fff !important;
                    font-size: 17px !important;
                    padding: 8px 25px !important;
                    line-height: 1.42857143;
                }
            }

            .el-dialog--center .el-dialog__header {
                text-align: center;
                border-bottom: 1px solid #e4e8f1;
            }

            .el-dialog {
                background: #18242F;
                padding: 15px;
                .el-dialog__header {
                    border-bottom: 2px solid #0a566d;
                }
                .el-dialog__title{
                    font-size: 20px;
                }
            }

            .el-select input {
                background: #233d53 !important;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                color:#fff !important;
                /*margin-left: -1px;*/
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }
            .el-input-group__prepend{
                border-radius: 9px;
                .el-select{
                    margin:-11px;
                }
            }

            .el-select:hover {
                background: #233d53;
                margin-left: -11px;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }

            .el-input-group__append, .el-input-group__prepend, .el-select .el-input .el-input__icon {
                color: #fff;
            }
            .el-select .el-input .el-input__icon{
                font-size: 18px;
                font-weight: 700;
            }

            .add-power-player-button {
                border-radius: 5px;
                background-color: #F47000;
                border-color: #F47000;
                font-size: 17px;
                font-weight: normal;
            }
            .el-input__inner{
                height: 50px;
                font-size: 15px;
            }

        }

        .button__upload {
            padding: 10px 25px 9px;
            background-color: #0044B1;
            border: none;
            font-size: 16px;
            line-height: 19px;
            font-weight: 500;
            color: white;
            border-radius: 5px;
            position: relative;
            overflow: hidden;

            .upload__input {
                position: absolute;
                top: -100px;
                left: -200px;
                width: 500px;
                height: 300px;
                opacity: 0;
                cursor: pointer;
            }

        }

        .hero__content {
            .project__title {
                margin-bottom: 0;
                font-size: 24px;
                line-height: 28px;
                color: var(--color-content);
                margin-right: 23px;
                position: relative;
                font-weight: 700 !important;
                margin-top: 0;
            }

            .project__video__count {
                font-size: 24px;
                line-height: 28px;
                color: var(--color-content);
                opacity: .7;
            }
        }

        .content__body {
            background-color: #eff8fa;
            padding: 30px 30px 30px;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, .04);
            border-radius: 12px;
            border: 1px solid #8ad6ec;

        }
    }

    @media (max-width: 960px) {
        .power-player-page {
            padding: 10px 25px 20px;

        }
    }

</style>
